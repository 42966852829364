<template>
  <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
  <div class="min-h-screen w-full bg-teal-900 px-6">
    <AdminHeader />

    <!-- Main Content -->
    <div class="max-w-4xl mx-auto pb-8">
      <!-- Title -->
      <h1 class="text-white text-2xl text-left mb-10">
        Invoices
        <span class="-ml-1 text-4xl" style="color: #00A388">.</span>
      </h1>

      <!-- Invoices Table -->
      <div class="mb-10">
        <table class="w-full text-left border-separate border-spacing-0">
          <thead>
            <tr>
              <th
                style="color:rgb(0, 163, 136)"
                class="pb-4 font-normal border-b border-[#707070] border-opacity-20"
              >
                Date
              </th>
              <th
                style="color:rgb(0, 163, 136)"
                class="pb-4 font-normal border-b border-[#707070] border-opacity-20"
              >
                Description
              </th>
              <th
                style="color:rgb(0, 163, 136)"
                class="pb-4 font-normal text-left border-b border-[#707070] border-opacity-20"
              >
                Total
              </th>
              <th
                style="color:rgb(0, 163, 136)"
                class="pb-4 font-normal text-left border-b border-[#707070] border-opacity-20"
              >
                Invoice
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(invoice, index) in invoices"
              :key="index"
              class="border-b border-[#707070] border-opacity-20 cursor-pointer hover:bg-opacity-10 hover:bg-white"
              @click="showInvoiceDetails(invoice)"
            >
              <td class="py-4 ">{{ invoice.date }}</td>
              <td class="py-4 text-white">{{ invoice.description }}</td>
              <td class="py-4 text-[#707070] text-left">
                ${{ invoice.total }}
              </td>
              <td class="py-4 text-left">
                <span class="text-[#00BCD4]">{{ invoice.invoice_number }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Back Button -->
      <button
        @click="$router.back()"
        class="mt-14 text-white px-6 py-4 rounded-md gap-5 flex items-center space-x-2"
        style="background-color: #204952"
      >
        <span class="material-icons text-xl arrow-left-icon">arrow_left</span>
        <span>Back</span>
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import AdminHeader from "@/components/AdminHeader.vue";
import Loading from "vue-loading-overlay";

export default {
  name: "VendorInvoices",
  components: {
    AdminHeader,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      loader: "bars",
      invoices: [],
    };
  },
  mounted() {
    this.fetchInvoices();
  },
  methods: {
    async fetchInvoices() {
      const vendorId = this.$route.query.id;
      if (!vendorId) return;

      this.isLoading = true;
      try {
        const response = await axios.get(`/user/${vendorId}/invoices`, {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("access")).access_token
            }`,
          },
        });

        if (response.data.success) {
          this.invoices = response.data.data.map((invoice) => ({
            id: invoice.id,
            date: new Date(invoice.created_at).toLocaleDateString("en-AU", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            }),
            description:
              invoice.transaction_desc || "Description/title to go here",
            total: invoice.amount,
            invoice_number: invoice.invoice_number || invoice.id,
            link: invoice.invoice_url || "#",
          }));
        } else {
          throw new Error(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching invoices:", error);
        this.$swal.fire({
          icon: "error",
          title: error.response?.data?.message || "Failed to fetch invoices",
          toast: true,
          position: "top-end",
          timer: 3000,
          showConfirmButton: false,
        });

        // Set empty array if no invoices found
        this.invoices = [];
      } finally {
        this.isLoading = false;
      }
    },
    showInvoiceDetails(invoice) {
      this.$router.push({
        name: "AdminVendorInvoiceDetails",
        query: {
          id: this.$route.query.id,
          invoice_id: invoice.id,
        },
      });
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

.bg-teal-900 {
  background-color: #06262d;
}

.arrow-left-icon {
  color: #00a388;
  background-color: #002f28;
  display: inline-flex;
  border-radius: 4px;
}

/* Table Styles */
table {
  border-collapse: separate;
  border-spacing: 0;
}

th,
td {
  color: whitesmoke;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(112, 112, 112, 0.2);
}

th {
  font-weight: normal;
  padding-bottom: 1rem;
}

td {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

tbody tr:last-child td {
  border-bottom-width: 1px;
}

a {
  color: #00bcd4;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>
